import React from "react";
import { observer } from "mobx-react";
import {
  foundation,
  pointerEvt,
  spacing,
  Notice,
  contents,
  Body1,
  Service,
  Business,
  Company,
  Document,
  Logout,
} from "ms-ui";
import styled from "styled-components";

export interface IMenu {
  onNotiHandler: () => void;
  onServiceHandler: () => void;
  onB2BHandler: () => void;
  onIntroHandler: () => void;
  onTermsHandler: () => void;
  onLogoutHandler: () => void;
  logoutVisible: boolean;
}

export const Menu = observer(
  ({
    onNotiHandler,
    onServiceHandler,
    onB2BHandler,
    onIntroHandler,
    onTermsHandler,
    onLogoutHandler,
    logoutVisible,
  }: IMenu) => {
    return (
      <>
        <StDiv1>
          <StDiv2 data-id={`aside_noti`} onClick={onNotiHandler}>
            <StDiv3>
              <Notice color={"#ffffff"} />
            </StDiv3>
            <StDiv4>
              <StDiv5>공지 & 이용방법</StDiv5>
            </StDiv4>
            <StDiv6></StDiv6>
          </StDiv2>
          <StDiv7 data-id={`aside_service`} onClick={onServiceHandler}>
            <StDiv8>
              <Service color={"#ffffff"} />
            </StDiv8>
            <StDiv9>
              <StDiv10>서비스 소개</StDiv10>
            </StDiv9>
            <StDiv11></StDiv11>
          </StDiv7>
          <StDiv12 data-id={`aside_B2B`} onClick={onB2BHandler}>
            <StDiv13>
              <Business color={"#ffffff"} />
            </StDiv13>
            <StDiv14>
              <StDiv15>
                모두의셔틀 비즈니스 {"("}상담문의{")"}
              </StDiv15>
            </StDiv14>
            <StDiv16></StDiv16>
          </StDiv12>
          <StDiv17 data-id={`aside_intro`} onClick={onIntroHandler}>
            <StDiv18>
              <Company color={"#ffffff"} />
            </StDiv18>
            <StDiv19>
              <StDiv20>회사소개</StDiv20>
            </StDiv19>
            <StDiv21></StDiv21>
          </StDiv17>
          <StDiv22 data-id={`aside_terms`} onClick={onTermsHandler}>
            <StDiv23>
              <Document color={"#ffffff"} />
            </StDiv23>
            <StDiv24>
              <StDiv25>정책 및 약관</StDiv25>
            </StDiv24>
            <StDiv26></StDiv26>
          </StDiv22>
          {logoutVisible && (
            <StDiv27 data-id={`aside_logout`} onClick={onLogoutHandler}>
              <StDiv28>
                <Logout color={"#ffffff"} />
              </StDiv28>
              <StDiv29>
                <StDiv30>로그아웃</StDiv30>
              </StDiv29>
            </StDiv27>
          )}
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background: ${foundation.normal.primary.value};
`;

const StDiv2 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv4 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.tertiary.value};
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  display: flex;
  width: 16px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv7 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv9 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.tertiary.value};
  width: 100%;
`;

const StDiv10 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv11 = styled.div`
  display: flex;
  width: 16px;
  height: 56px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv12 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv13 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv14 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.tertiary.value};
  width: 100%;
`;

const StDiv15 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv16 = styled.div`
  display: flex;
  width: 16px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv17 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv18 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv19 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.tertiary.value};
  width: 100%;
`;

const StDiv20 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv21 = styled.div`
  display: flex;
  width: 16px;
  height: 56px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv22 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv23 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv24 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.tertiary.value};
  width: 100%;
`;

const StDiv25 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;

const StDiv26 = styled.div`
  display: flex;
  width: 16px;
  height: 56px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv27 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const StDiv28 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv29 = styled.div`
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv30 = styled.div`
  ${Body1};
  color: ${contents.secondary.value};
  width: fit-content;
`;
