import { ShareEvent } from "src/constants/evt/share";
import { parseUrl, stringifyUrl } from "../url";
import { facebookShare } from "./facebook";
import { kakaotalkShare } from "./kakao";
import { naverShare } from "./naver";

//UTM 태그 생성기

/*
  URL에 UTM코드를 붙여서 유입 경로를 알 수 있게 하기 위함.
  1. utm_source : 내 사이트에 들어오는 유입 플랫폼 (ex. google, naver, daum 등)
  2. utm_medium : 광고 또는 마케팅 매체 (ex. email, cpc, banner 등)
  3. utm_campaign : 캠페인, 상품 등의 이름, 프로모션 코드 등 (ex. summer-travel, chocolate 등)
  4. utm_term : 유료 검색 키워드 
  5. utm_content : 사용자에게 노출된 광고 버전 구분

  1~3 은 필수,
  4,5번은 옵션

  ex) www.사이트.com?utm_source=google&utm_medium=cpc&utm_campaign=summer&utm_term=clothes+discount&utm_content=limited_edition
*/

export interface InfoForShare {
  refCd: string;
}

export const utmTagMaker = (option: {
  url: string; //공유할 url => ${MSHUTTLE_PATH.main}/invete/route
  social: "facebook" | "kakaotalk" | "naver" | "clip";
  content?: string;
  inviteProps: any;
}) => {
  const { url, social, inviteProps, content } = option;

  const newQuery = {
    ...inviteProps,
    utm_source: social,
    utm_medium: "share",
  };
  if (content !== undefined) {
    newQuery.utm_content = content;
  }

  return `${url}${stringifyUrl(newQuery)}`;
};

//UTM 태그 제거
export const removeUtmTag = (txt: string) => {
  try {
    const data = parseUrl(txt);
    Object.keys(data).forEach((key) => {
      if (key.indexOf("utm") === -1) {
      } else {
        delete data[key];
      }
    });
    return stringifyUrl(data);
  } catch (error) {
    return "";
  }
};

export const clickShareBtn = (
  snsName: "facebook" | "kakaotalk" | "naver",
  url: string,
  data: InfoForShare
) => {
  ShareEvent(data);
  shareFunc(snsName, url, data);
};

const shareFunc = (
  snsName: "facebook" | "kakaotalk" | "naver",
  url: string,
  data: any
) => {
  switch (snsName) {
    case "facebook":
      facebookShare(url, data);
      break;
    case "kakaotalk":
      kakaotalkShare(url, data);
      break;
    case "naver":
      naverShare(url, data);
      break;
    default:
      break;
  }
};
