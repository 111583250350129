import Cookies from "js-cookie";

export const pushDataLayer = (evtName: string, parameter: any = {}) => {
  if (window.dataLayer !== undefined && window.dataLayer !== null) {
    window.dataLayer.push({ event: evtName, ...parameter });
  }
};

/**
 *
 * @param key - eventName
 * @param properties - label and ...properties
 */
export const sendHackle = (key: string, label: string, properties: object) => {
  if (window.hackleClient !== undefined && window.hackleClient !== null) {
    window.hackleClient?.onReady(() => {
      const utm_source = Cookies.get(`utm_source`);
      const utm_medium = Cookies.get(`utm_medium`);
      const utm_campaign = Cookies.get(`utm_campaign`);

      const utm_content = Cookies.get(`utm_content`);
      const utm_term = Cookies.get(`utm_term`);

      const before_utm_source = Cookies.get(`before_utm_source`);
      const before_utm_medium = Cookies.get(`before_utm_medium`);
      const before_utm_campaign = Cookies.get(`before_utm_campaign`);

      const before_utm_content = Cookies.get(`before_utm_content`);
      const before_utm_term = Cookies.get(`before_utm_term`);

      const evt = {
        ...properties,

        utm_source,
        utm_medium,
        utm_campaign,

        utm_content,
        utm_term,

        before_utm_source,
        before_utm_medium,
        before_utm_campaign,

        before_utm_content,
        before_utm_term,
      };

      window.hackleClient?.track({ key, properties: { ...evt } });
    });
  }
};

export const pushUserIdIntoDataLayer = (refCd: string) => {
  if (window.dataLayer !== undefined && window.dataLayer !== null) {
    window.dataLayer.push({
      userId: refCd,
    });
  }
};
