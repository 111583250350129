import { observer } from "mobx-react";
import { MyReferral } from "./MyReferral";
import { AuthStore } from "src/store/global/AuthStore";
import { useRef, useState } from "react";
import {
  InfoForShare,
  clickShareBtn,
  utmTagMaker,
} from "src/func/share/helper";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { ShareModal } from "src/components/ShareModal";
import { ModalAlert } from "ms-ui";

export const MyReferralTpl = observer((props: { authStore: AuthStore }) => {
  const { authStore } = props;
  const { user } = authStore;

  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);

  const dataToShare: InfoForShare = {
    refCd: user?.refCd!,
  };

  const getUrl = (snsName: "facebook" | "kakaotalk" | "naver" | "clip") => {
    let url = `${MSHUTTLE_PATH.main}/invite/main`;

    let inviteProps: any = {};

    inviteProps.rcode = user?.refCd!;

    return utmTagMaker({
      url,
      social: snsName,
      inviteProps,
    });
  };

  return (
    <>
      {modal && (
        <ShareModal
          cancel={() => {
            setModal(false);
          }}
          kakaotalk={{
            onClick: () => {
              clickShareBtn("kakaotalk", getUrl("kakaotalk"), dataToShare);
            },
          }}
          url={{
            clip: `${getUrl("clip")}`,
            modaltext: "공유링크가 복사되었습니다.",
            confirm: () => {
              setModal(false);
            },
          }}
        />
      )}

      <ModalAlert
        open={alert}
        msg={`추천인코드가 복사되었습니다. \n ${user?.refCd}`}
        onClick={() => {
          setAlert(false);
        }}
      />

      <MyReferral
        onInviteHandler={() => {
          setModal(true);
        }}
        onClickHandler={() => {
          navigator.clipboard
            .writeText(user?.refCd!)
            .then(() => {
              setAlert(true);
            })
            .catch((error) => {
              console.error("복사 실패:", error);
            });
        }}
        dataClipboardText={user?.refCd || ""}
      />
    </>
  );
});
