import { observer } from "mobx-react";
import { MyShuttleInfo } from "./MyShuttleInfo";
import { useStores } from "src/store/global";
import { MSHUTTLE_PATH } from "src/constants/addr";

export const MyShuttleInfoTpl = observer(() => {
  const { authStore } = useStores();

  return (
    <MyShuttleInfo
      visible={authStore.isBoarding === true}
      onClickHandler={() => {
        window.location.href = `${MSHUTTLE_PATH.runn}/boarding`;
      }}
    />
  );
});
