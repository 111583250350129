import React from "react";

export const Kakaotalk = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9 2C5.88 2 1 5.92 1 10.76C1 13.9 3.07 16.66 6.17 18.21C5.94 19.07 5.35 21.34 5.22 21.83C5.07 22.43 5.44 22.42 5.68 22.26C5.87 22.13 8.67 20.2 9.87 19.36C10.54 19.46 11.22 19.51 11.89 19.51C17.91 19.51 22.79 15.59 22.79 10.76C22.79 5.93 17.92 2 11.9 2Z"
        fill="#392020"
      />
      <path
        d="M5.59201 9.40304H4.48201C4.34201 9.40304 4.20201 9.37304 4.09201 9.29304C3.98201 9.22304 3.91201 9.11304 3.89201 8.99304C3.88201 8.96304 3.88201 8.92304 3.87201 8.88304C3.87201 8.73304 3.93201 8.59304 4.05201 8.50304C4.17201 8.40304 4.33201 8.36304 4.49201 8.36304H7.88201C8.02201 8.36304 8.15201 8.39304 8.27201 8.46304C8.38201 8.53304 8.45201 8.63304 8.48201 8.76304C8.48201 8.80304 8.49201 8.84304 8.49201 8.87304C8.50201 9.02304 8.43201 9.16304 8.32201 9.25304C8.19201 9.35304 8.04201 9.40304 7.88201 9.39304H6.77201V13.213C6.78201 13.373 6.72201 13.533 6.61201 13.653C6.50201 13.773 6.34201 13.833 6.18201 13.823C6.05201 13.823 5.91201 13.783 5.80201 13.703C5.70201 13.623 5.62201 13.503 5.60201 13.373C5.60201 13.323 5.59201 13.273 5.60201 13.213L5.59201 9.40304Z"
        fill="#FEE500"
      />
      <path
        d="M8.772 8.64301C8.812 8.52301 8.882 8.42301 8.982 8.35301C9.092 8.29301 9.212 8.26301 9.342 8.27301H9.622C9.752 8.27301 9.892 8.30301 10.002 8.36301C10.132 8.45301 10.212 8.57301 10.252 8.72301L11.672 12.833C11.712 12.943 11.742 13.053 11.772 13.163C11.772 13.203 11.782 13.253 11.792 13.303C11.792 13.443 11.732 13.583 11.622 13.683C11.522 13.783 11.382 13.843 11.232 13.843C11.002 13.863 10.782 13.723 10.702 13.503L10.402 12.603H8.512L8.202 13.503C8.132 13.723 7.912 13.863 7.682 13.833C7.562 13.833 7.432 13.803 7.332 13.723C7.242 13.653 7.172 13.543 7.152 13.423C7.152 13.383 7.142 13.343 7.142 13.303C7.142 13.233 7.152 13.153 7.172 13.083C7.192 13.003 7.222 12.913 7.252 12.833L8.772 8.64301ZM9.482 9.60301H9.462L8.772 11.753H10.152L9.482 9.60301Z"
        fill="#FEE500"
      />
      <path
        d="M11.972 8.88299C11.962 8.72299 12.022 8.56299 12.132 8.44299C12.242 8.32299 12.402 8.26299 12.562 8.27299C12.702 8.27299 12.832 8.31299 12.942 8.39299C13.042 8.47299 13.112 8.59299 13.142 8.72299C13.152 8.77299 13.152 8.82299 13.152 8.87299V12.703H15.112C15.252 12.693 15.392 12.733 15.512 12.803C15.622 12.873 15.692 12.983 15.712 13.103C15.722 13.143 15.722 13.173 15.732 13.213C15.742 13.363 15.672 13.503 15.562 13.593C15.432 13.683 15.282 13.733 15.122 13.723H12.662C12.502 13.743 12.352 13.703 12.222 13.623C12.102 13.543 12.022 13.403 12.002 13.263C11.982 13.183 11.972 13.103 11.972 13.023V8.88299Z"
        fill="#FEE500"
      />
      <path
        d="M15.832 8.88301C15.822 8.72301 15.882 8.56301 15.992 8.44301C16.102 8.33301 16.262 8.27301 16.422 8.27301C16.562 8.27301 16.692 8.31301 16.802 8.39301C16.912 8.47301 16.982 8.59301 17.002 8.72301C17.012 8.77301 17.022 8.82301 17.012 8.87301V10.653L18.852 8.53301C18.922 8.45301 18.992 8.39301 19.072 8.33301C19.152 8.29301 19.232 8.27301 19.322 8.27301C19.452 8.27301 19.572 8.31301 19.672 8.38301C19.772 8.44301 19.852 8.55301 19.872 8.67301C19.872 8.67301 19.872 8.69301 19.882 8.71301C19.882 8.73301 19.882 8.74301 19.882 8.76301C19.882 8.84301 19.862 8.91301 19.832 8.98301C19.792 9.05301 19.742 9.12301 19.692 9.18301L18.482 10.503L19.912 12.763L19.952 12.833C20.022 12.933 20.082 13.053 20.122 13.173C20.122 13.183 20.122 13.193 20.132 13.213C20.132 13.223 20.132 13.243 20.132 13.253C20.152 13.423 20.082 13.593 19.952 13.703C19.832 13.793 19.682 13.843 19.522 13.843C19.422 13.843 19.322 13.823 19.232 13.783C19.142 13.713 19.062 13.633 19.002 13.523L17.652 11.343L17.012 12.013L17.022 13.233C17.032 13.393 16.972 13.553 16.862 13.673C16.752 13.783 16.592 13.853 16.432 13.843C16.292 13.843 16.162 13.803 16.052 13.723C15.952 13.643 15.872 13.523 15.852 13.393C15.842 13.343 15.842 13.293 15.842 13.233L15.832 8.88301Z"
        fill="#FEE500"
      />
    </svg>
  );
};
