import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, pointerEvt, Header2, More, Body2 } from "ms-ui";
import styled from "styled-components";

export interface ILogin {
  onLoginHandler: () => void;
}

export const Login = observer(({ onLoginHandler }: ILogin) => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3 data-id={`aside_login`} onClick={onLoginHandler}>
            <StDiv4>로그인 및 회원가입</StDiv4>
            <More color={"#8C8C8C"} />
          </StDiv3>
        </StDiv2>
        <StDiv5>로그인하고 꿀잠 출근 라이프를 시작하세요!</StDiv5>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[32].value} ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[8].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv3 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  align-items: center;
  gap: ${spacing[8].value};
  cursor: pointer;
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${Header2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Body2};
  color: ${contents.accent.value};
  width: fit-content;
`;
