import React from "react";
import { observer } from "mobx-react";
import { Paragraph, spacing, contents, Header2, Body2 } from "ms-ui";
import styled from "styled-components";

export interface IMyInfo {
  userNm: string;
}

export const MyInfo = observer(({ userNm }: IMyInfo) => {
  return (
    <>
      <StDiv1 noMargin={true}>
        <StDiv2>
          <StDiv3>
            <StDiv4>{userNm}</StDiv4>
            <StDiv5>님</StDiv5>
          </StDiv3>
        </StDiv2>
        <StDiv6>안녕하세요. 회사 앞까지 편하게 모셔드릴게요!</StDiv6>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled(Paragraph)`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
`;

const StDiv2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Header2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Header2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Body2};
  align-self: stretch;
  color: ${contents.accent.value};
`;
