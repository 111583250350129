import { useEffect, useRef, useState } from "react";
import ClipboardJS from "clipboard";
import { ModalAlert } from "ms-ui";
import { observer } from "mobx-react";

interface DefaultProps {
  value: {
    text: string | JSX.Element;
    clip: string;
    modaltext: string;
    msg: string;
  };
  successCallback?: Function;
  errorCallback?: Function;
  onConfirm?: Function;
}

const ClipButton = (props: DefaultProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [alert, setAlert] = useState({
    view: false,
    type: ""
  });

  useEffect(() => {
    const cl = new ClipboardJS(ref.current!);

    cl.on("success", () => {
      setAlert({
        view: true,
        type: "success"
      });
    });

    cl.on("error", () => {
      setAlert({
        view: true,
        type: "error"
      });
    });

    return () => cl.destroy();
  }, []);

  return (
    <>
      <button ref={ref} data-clipboard-text={props.value.clip}>
        {props.value.text}
      </button>
      <ModalAlert
        open={alert.view === true}
        msg={alert.type === "success" ? props.value.modaltext : props.value.msg}
        onClick={() => {
          setAlert({
            view: false,
            type: ""
          });
          props.onConfirm && props.onConfirm();
        }}
      />
    </>
  );
};

export default observer(ClipButton);
