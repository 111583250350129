import { InfoForShare } from "./helper";
import { winOpen } from "../window";

//이건 알림받기에서만 쓸거니까 필요없는 부분 다 잘라냄.
export const naverShare = (url: string, shareInfo: InfoForShare) => {
  let title = `추천인 코드 ${shareInfo.refCd} 입력하고 할인 혜택 받아서 꿀잠 출근라이프를 시작하세요!`;

  shareLink(url, title);
};

const shareLink = (url: string, title: string) => {
  const encodedUrl = encodeURI(encodeURIComponent(`${url}`));
  const encodedTitle = encodeURI(title);
  const shareURL = `https://share.naver.com/web/shareView.nhn?url=${encodedUrl}&title=${encodedTitle}`;
  winOpen(shareURL, "_blank", "location=no");
};
