import { InfoForShare } from "./helper";
import { ms_file } from "src/constants/addr";

// 데이터 가공
export const kakaotalkShare = (url: string, shareInfo: InfoForShare) => {
  let title = `추천인 코드 ${shareInfo.refCd} 입력하고 할인 혜택 받아서 꿀잠 출근라이프를 시작하세요!`;
  let description = `집에서 회사까지 한번에 모셔드립니다.`;

  kakaoShare({
    href: url,
    title,
    description,
    file: `${ms_file}/image/icon/share/thumb_kakao_800_800.png`, //`${msfile}/image/background/share/kakao_share.png`,
  });
};

//공유하기
const kakaoShare = async (options: {
  title: string; // 공유 메세지 타이틀
  description?: string; // 공유 메세지 내용 (있으면 통째로 변경됨 / 없으면 defaultMsg로 생성함)
  href: string;
  file: string;
}) => {
  const { href, title, description, file } = options;
  const obj = {
    content: {
      title: title,
      description: description,
      imageUrl: file,
      link: {
        mobileWebUrl: href,
        webUrl: href,
      },
    },
    buttons: [
      {
        title: "모두의셔틀 웹사이트",
        link: {
          mobileWebUrl: href,
          webUrl: href,
        },
      },
    ],
  };

  console.log(`web`);
  if (typeof window.Kakao !== "undefined") {
    const webObj = {
      ...obj,
      objectType: "feed",
      installTalk: true,
    };

    window.Kakao.Share.sendDefault(webObj);
  }
};
