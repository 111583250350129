import React from "react";
import { observer } from "mobx-react";
import {
  contents,
  spacing,
  Info,
  pointerEvt,
  Body1,
  Apply,
  Coupon,
} from "ms-ui";
import styled from "styled-components";

export interface IMyMenu {
  onMyInfoClickHandler: () => void;
  onApplyClickHandler: () => void;
  onCpnClickHandler: () => void;
}

export const MyMenu = observer(
  ({
    onMyInfoClickHandler,
    onApplyClickHandler,
    onCpnClickHandler,
  }: IMyMenu) => {
    return (
      <>
        <StDiv1>
          <StDiv2 onClick={onMyInfoClickHandler}>
            <StDiv3>
              <Info color={"#4FD3FC"} />
            </StDiv3>
            <StDiv4 data-id={`aside_myinfo`}>
              <StDiv5>내 정보</StDiv5>
            </StDiv4>
            <StDiv6></StDiv6>
          </StDiv2>
          <StDiv7 onClick={onApplyClickHandler}>
            <StDiv8>
              <Apply color={"#4FD3FC"} />
            </StDiv8>
            <StDiv9 data-id={`aside_apply`}>
              <StDiv10>신청내역</StDiv10>
            </StDiv9>
            <StDiv11></StDiv11>
          </StDiv7>
          <StDiv12 onClick={onCpnClickHandler}>
            <StDiv13>
              <Coupon color={"#4FD3FC"} />
            </StDiv13>
            <StDiv14 data-id={`aside_cpn`}>
              <StDiv15>쿠폰함</StDiv15>
            </StDiv14>
          </StDiv12>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value} ${spacing[16].value} ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.subtitle.value};
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv6 = styled.div`
  display: flex;
  width: 16px;
  height: 56px;
  padding: ${spacing[16].value} ${spacing[16].value} ${spacing[16].value} 0px;
`;

const StDiv7 = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv9 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  box-shadow: 0 0.1px 0 0 ${contents.subtitle.value};
  width: 100%;
`;

const StDiv10 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv11 = styled.div`
  display: flex;
  width: 16px;
  height: 56px;
  padding: ${spacing[16].value} 0px;
`;

const StDiv12 = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
`;

const StDiv13 = styled.div`
  display: flex;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv14 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[16].value} 0px;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv15 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  width: fit-content;
`;
