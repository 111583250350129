import { useEffect, useRef } from "react";
import styled from "styled-components";

import {
  CenterFlex,
  ModalContainer,
  Subtitle3,
  contents,
  pointerEvt,
} from "ms-ui";
import { Kakaotalk } from "./Kakaotalk";
import { Facebook } from "./Facebook";
import { Naver } from "./Naver";
import ClipButton from "./ClipButton";

interface styledShareModalProps {}

interface IShareModal extends styledShareModalProps {
  kakaotalk?: {
    id?: string;
    onClick: () => void;
  };
  facebook?: {
    id?: string;
    onClick: () => void;
  };
  naver?: {
    id?: string;
    onClick: () => void;
  };
  url?: any;
  cancel?: () => void;
}

export const ShareModal = (props: IShareModal) => {
  const { kakaotalk, facebook, naver, url, cancel } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // document.addEventListener("touchstart", (e) => {
    //   if (ref.current?.contains(e.target as Node) === false) {
    //     cancel && cancel();
    //   }
    // });
    document.addEventListener("mousedown", (e) => {
      if (ref.current?.contains(e.target as Node) === false) {
        cancel && cancel();
      }
    });

    return () => {
      // document.removeEventListener("touchstart", (e) => {
      //   if (ref.current?.contains(e.target as Node) === false) {
      //     cancel && cancel();
      //   }
      // });
      document.removeEventListener("mousedown", (e) => {
        if (ref.current?.contains(e.target as Node) === false) {
          cancel && cancel();
        }
      });
    };
  }, []);

  return (
    <ModalContainer>
      <StyledSocialButtons ref={ref}>
        {/* kakaotalk */}
        {kakaotalk !== undefined ? (
          <StyledShareIcon
            color={`#FEE500`}
            onClick={(e) => kakaotalk.onClick()}
            // id={kakaotalk.id && kakaotalk.id}
          >
            <Kakaotalk />
          </StyledShareIcon>
        ) : null}

        {/* facebook */}
        {facebook !== undefined ? (
          <StyledShareIcon
            color={`#4267B2`}
            onClick={(e) => facebook.onClick()}
            // id={facebook.id && facebook.id}
          >
            <Facebook />
          </StyledShareIcon>
        ) : null}

        {/* naver */}
        {naver !== undefined ? (
          <StyledShareIcon
            color={`#1EC800`}
            onClick={(e) => naver.onClick()}
            // id={naver.id && naver.id}
          >
            <Naver />
          </StyledShareIcon>
        ) : null}

        {/* url 공유 */}
        {url !== undefined ? (
          <ClipButton
            value={{
              text: (
                <StyledShareIcon
                  color={`var(--colors-subblue-500)`}
                  // id={url.id && url.id}
                >
                  URL
                </StyledShareIcon>
              ),
              clip: url.clip,
              modaltext: url.modaltext,
              msg: "잠시후 다시 시도 바랍니다.",
            }}
            onConfirm={url.confirm}
            successCallback={url.success}
            errorCallback={url.error}
          />
        ) : null}
      </StyledSocialButtons>
    </ModalContainer>
  );
};

const StyledSocialButtons = styled.div`
  width: 60px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledShareIcon = styled.div<{ color: string }>`
  cursor: pointer;
  background-color: ${(p) => p.color};
  margin: 0 auto var(--spacing-16);
  width: 60px;
  height: 60px;
  border-radius: 30px;
  ${CenterFlex};
  ${Subtitle3};
  color: ${contents.secondary.value};

  svg {
    width: 30px;
    height: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(p) => pointerEvt(p["data-id"])};
`;
