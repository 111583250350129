import { AuthStore } from "src/store/global/AuthStore";
import { observer } from "mobx-react";
import { LoginTpl } from "./LoginTpl";
import { MyShuttleInfoTpl } from "./MyShuttleInfoTpl";
import { MyInfoTpl } from "./MyInfoTpl";
import { MyReferralTpl } from "./MyReferralTpl";
import { MyMenuTpl } from "./MyMenuTpl";

interface IProps {
  authStore: AuthStore;
}

export const UserInfo = observer(({ authStore }: IProps) => {
  const { user } = authStore;

  return (
    <>
      {!user && (
        <>
          <LoginTpl />
        </>
      )}
      {user && (
        <>
          <MyInfoTpl authStore={authStore} />
          <MyShuttleInfoTpl />
          <MyReferralTpl authStore={authStore} />
          <MyMenuTpl />
        </>
      )}
    </>
  );
});
