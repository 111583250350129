import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  pointerEvt,
  borderRadius,
  foundation,
  colors,
  Body2,
  Copy,
} from "ms-ui";
import styled from "styled-components";

export interface IMyReferral {
  onInviteHandler: () => void;
  onClickHandler: () => void;
  dataClipboardText: string;
}

export const MyReferral = observer(
  ({ onInviteHandler, onClickHandler, dataClipboardText }: IMyReferral) => {
    return (
      <>
        <StDiv1>
          <StDiv2 data-id={`mypage_inviteFriend`} onClick={onInviteHandler}>
            <StDiv3>친구 초대하기</StDiv3>
          </StDiv2>
          <StDiv4
            data-id={`mypage_refCd`}
            onClick={onClickHandler}
            data-clipboard-text={dataClipboardText}
          >
            <StDiv5>내 추천코드</StDiv5>
            <Copy color={"#4852E3"} />
          </StDiv4>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  gap: ${spacing[16].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[8].value} ${spacing[12].value};
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: ${borderRadius[5].value};
  background: ${foundation.light.primary.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Body2};
  color: ${colors.mainblue[500].value};
  text-align: center;
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  display: flex;
  padding: ${spacing[8].value} ${spacing[12].value};
  justify-content: center;
  align-items: center;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  border-radius: ${borderRadius[5].value};
  background: ${foundation.light.primary.value};
  cursor: pointer;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Body2};
  color: ${colors.mainblue[500].value};
  text-align: center;
  width: fit-content;
`;
