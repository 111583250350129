import { InfoForShare } from "./helper";
import { MSHUTTLE_DATA } from "src/constants/addr";
import { stringifyUrl } from "../url";
import { winOpen } from "../window";

//가공
export const facebookShare = (url: string, shareInfo: InfoForShare) => {
  let quote = `추천인 코드 ${shareInfo.refCd} 입력하고 할인 혜택 받아서 꿀잠 출근라이프를 시작하세요!`;

  facebookLink({
    method: "share",
    display: "popup",
    href: `${url}`,
    quote,
    hashtag: "#모두의셔틀",
  });
};

//공유
export const facebookLink = (
  { method, display, href, quote, hashtag }: any,
  callback?: any
) => {
  if (typeof window.FB !== "undefined") {
    if (
      MSHUTTLE_DATA.os.name.indexOf("Windows") === -1 &&
      MSHUTTLE_DATA.browser.name.indexOf("Explorer") === -1
    ) {
      if (window.FB !== null && window.FB !== undefined) {
        console.log({
          method,
          display,
          href,
          quote,
          hashtag,
        });
        window.FB.ui(
          {
            method,
            display,
            href,
            quote,
            hashtag,
          },
          (response: any) => {}
        );
      } else {
        callback && callback();
      }
    } else {
      const fb = "https://www.facebook.com/dialog/share";
      const setting = {
        app_id: MSHUTTLE_DATA.FACEBOOK,
        display,
        href,
        quote,
        hashtag,
      };
      console.log(setting);
      const search = stringifyUrl(setting);
      winOpen(fb + search);
    }
  } else {
    callback && callback();
  }
};
