import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Button, More, foundation } from "ms-ui";
import styled from "styled-components";

export interface IMyShuttleInfo {
  visible: boolean;
  onClickHandler: () => void;
}

export const MyShuttleInfo = observer(
  ({ visible, onClickHandler }: IMyShuttleInfo) => {
    return (
      <>
        {visible && (
          <StDiv1>
            <Button
              color={`primary`}
              size={`sm`}
              radius={`5`}
              outline={true}
              data-id={`aside_shuttle`}
              onClick={onClickHandler}
              width={`100%`}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                오늘 탑승할 경로 및 셔틀위치 확인
                <More color={foundation.normal.primary.value} />
              </div>
            </Button>
          </StDiv1>
        )}
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: 0px ${spacing[16].value};
  background: ${contents.secondary.value};
`;
