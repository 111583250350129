import { observer } from "mobx-react";
import { Menu } from "./Menu";
import { useURI } from "ms_router";
import { useEffect, useState } from "react";
import { winOpen } from "src/func/window";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { ModalLoader } from "ms-ui";
import { AuthStore } from "src/store/global/AuthStore";
import { isNil } from "ms_data";

export const MenuTpl = observer((props: { authStore: AuthStore }) => {
  const { authStore } = props;
  const { navigate } = useURI();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("pagehide", () => {
      setLoading(false);
    });
    return () => {
      window.removeEventListener("pagehide", () => {
        setLoading(false);
      });
    };
  }, []);

  return (
    <>
      <ModalLoader active={isLoading} />
      <Menu
        onNotiHandler={() => {
          winOpen("https://guide.modooshuttle.com/", "_blank");
        }}
        onServiceHandler={() => {
          navigate("/", {
            state: "intro",
          });
        }}
        onB2BHandler={() => {
          setLoading(true);
          window.location.href = MSHUTTLE_PATH.biz;
        }}
        onIntroHandler={() => {
          navigate("/intro");
        }}
        onTermsHandler={() => {
          winOpen(`https://guide.modooshuttle.com/terms`, "_blank");
        }}
        onLogoutHandler={() => {
          try {
            authStore.logout();
            window.dataLayer.push({ userId: null });
          } catch (e) {
            console.log(e);
          }
        }}
        logoutVisible={!isNil(authStore.user)}
      />
    </>
  );
});
