import { observer } from "mobx-react";
import { MyMenu } from "./MyMenu";
import { useURI } from "ms_router";
import { channelIO } from "src/func/tracking/channel";

export const MyMenuTpl = observer(() => {
  const { navigate } = useURI();

  const clickMenu = (path: string, chEvt: string) => {
    channelIO.event(chEvt, {});
    navigate(path);
  };

  return (
    <MyMenu
      onMyInfoClickHandler={() => {
        clickMenu("/mypage/myinfo", "goMyinfo");
      }}
      onApplyClickHandler={() => {
        clickMenu("/mypage/apply", "goApplyInfo");
      }}
      onCpnClickHandler={() => {
        clickMenu("/mypage/coupon", "goCoupon");
      }}
    />
  );
});
