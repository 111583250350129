import { observer } from "mobx-react";
import { useStores } from "src/store/global";
import { UserInfo } from "./view/UserInfo";
import { MenuTpl } from "./view/MenuTpl";
import { ModalAlert, Nav, foundation } from "ms-ui";
import styled from "styled-components";

const Aside = observer(() => {
  const { authStore } = useStores();
  const { alert } = authStore;

  return (
    <>
      <ModalAlert open={alert.open} msg={alert.msg} onClick={alert.fn} />

      <Nav title="더 보기" />

      <StAsideBlock>
        <UserInfo authStore={authStore} />
        <div
          style={{
            paddingBottom: "100px",
            background: foundation.normal.primary.value,
          }}
        >
          <MenuTpl authStore={authStore} />
        </div>
      </StAsideBlock>
    </>
  );
});

export default Aside;

const StAsideBlock = styled.div`
  background-color: ${foundation.normal.primary.value};
  height: calc(100dvh - 56px);
`;
