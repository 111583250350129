import { InfoForShare } from "src/func/share/helper";
import { channelIO } from "src/func/tracking/channel";
import { pushDataLayer } from "src/func/tracking/gtm";

export const ShareEvent = ({ refCd }: InfoForShare) => {
  let action = `Share_`;
  const label = `referral_code : ${refCd}`;

  // fbPixel.event(action, "Share", label);

  pushDataLayer(action, {
    category: "Share",
  });

  // sendHackle(action, label, {
  //   category: "Share"
  // });

  channelIO.event(action, { category: "Share", label });
};
