import { observer } from "mobx-react";
import { Login } from "./Login";
import { channelIO } from "src/func/tracking/channel";
import { getCurrentUrl, goLogin } from "src/func/url";
import { useLocation } from "react-router-dom";
import { useURI } from "ms_router";

export const LoginTpl = observer(() => {
  const { location } = useURI();

  const goAuth = () => {
    channelIO.event("goAuth", {});
    goLogin(getCurrentUrl(location as any));
  };

  return <Login onLoginHandler={goAuth} />;
});
